import React, { Component } from "react";
import Sidebar from "./components/Sidebar";
import Player from "./Player";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChannel: null,
    };

    this.selectChannel = this.selectChannel.bind(this);
  }

  selectChannel(channel) {
    this.setState({
      currentChannel: channel,
    });
  }

  render() {
    const currentChannel = this.state.currentChannel;
    return (
      <Sidebar selectChannel={this.selectChannel}>
        <div className="row justify-content-center">
          <div className="col">
          <div>
            <h1>{currentChannel ? currentChannel.name : "Select a channel"}</h1>
            {currentChannel ? <Player channel={currentChannel} /> : <div />}
          </div>
          </div>
        </div>
      </Sidebar>
    );
  }
}
