import React, { Component } from "react";
import SidebarToggle from "./SidebarToggle";
import SidebarCategory from "./SidebarCategory";
import SidebarChannelList from "./SidebarChannelList";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        searchTerm: "",
    };

    this.setSearchTerm = this.setSearchTerm.bind(this);
  }

  setSearchTerm(event) {
    this.setState({searchTerm: event.target.value});
  }

  render() {
    return (
      <nav>
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
          <a className="navbar-brand ps-3" href="index.html">
            TV7
          </a>
          <SidebarToggle />
        </nav>
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <nav
              className="sb-sidenav accordion sb-sidenav-dark"
              id="sidenavAccordion"
            >
              <div className="sb-sidenav-menu">
                <div className="nav">
                  <SidebarCategory name="Search for Channel" />

                  <div className="input-group input-group-sm mb-3 pr-3 pl-3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.searchTerm}
                      onChange={this.setSearchTerm}
                    />
                  </div>
                  <SidebarChannelList
                    selectChannel={this.props.selectChannel}
                    searchTerm={this.state.searchTerm}
                  />
                </div>
              </div>
            </nav>
          </div>
          <div id="layoutSidenav_content">
            <main className="container">{this.props.children}</main>
          </div>
        </div>
      </nav>
    );
  }
}
