import React from "react";
import ReactHlsPlayer from "react-hls-player";

export default function Player(props) {
  return (
    <div>
      <ReactHlsPlayer
        src={props.channel.hls_src}
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
      />
    </div>
  );
}
