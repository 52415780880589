import React, { Component } from "react";

export default class SidebarChannelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: null,
    };
  }

  componentDidMount() {
    let channelMenuEntry = [];
    fetch("https://api.tv.init7.net/api/tvchannel/")
      .then((response) => response.json())
      .then(
        (data) => {
          this.setState({
            channels: data.results,
          });

          return channelMenuEntry;
        },
        (error) => {
          console.error("Error fetching channel Data from API:", error);
        }
      );
  }

  computeChannelList() {
    let availableChannels = this.state.channels;
    
    if (this.props.searchTerm !== "") {
      availableChannels = this.state.channels.filter( ({ name }) => name.toLowerCase().includes(this.props.searchTerm.toLowerCase()) )
    }

    let channelMenuEntry = [];
    availableChannels.forEach((channel) => {
      channelMenuEntry.push(
        <div key={channel.pk}>
          <a
            className="nav-link"
            onClick={() => this.props.selectChannel(channel)}
          >
            <div className="sb-nav-link-icon">
              <img src={channel.logo} width="25" height="25" alt="" />
            </div>
            {channel.name}
          </a>
        </div>
      );
    });
    return channelMenuEntry;
  }

  render() {
    if (this.state.channels) {
      return this.computeChannelList();
    }
    return <div></div>;
  }
}
